import React from "react";
import {
  Button,
  IconButton,
  Box,
  Icon,
  useColorModeValue,
  CloseButton,
  Flex,
  Drawer,
  useDisclosure,
  DrawerContent,
  DrawerBody,
  Text,
  Link,
} from "@chakra-ui/react";
import {
  FiHome,
  FiTrendingUp,
  FiCompass,
  FiStar,
  FiSettings,
  FiMenu,
} from "react-icons/fi";
import { BsGoogle } from "react-icons/bs";
import { IconType } from "react-icons";
import { valueOrDefault } from "chart.js/dist/helpers/helpers.core";
import WeatherForecast from "../Body/WeatherForecast";
interface LinkItemProps {
  name: string;
  icon: IconType;
  url: string;
}

const LinkItems: Array<LinkItemProps> = [
  { name: "Home", icon: FiHome, url: "/" },
  { name: "Trending", icon: FiTrendingUp, url: "https://google.com" },
  { name: "Explore", icon: FiCompass, url: "a" },
  { name: "Favourites", icon: FiStar, url: "/" },
  { name: "Settings", icon: FiSettings, url: "/" },
  { name: "Google", icon: BsGoogle, url: "https://google.com" },
  { name: "Weather", icon: BsGoogle, url: "https://google.com" },
];

const SideBarChakraUI = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Flex color="white">
      <Box w={{ base: "120px", md: "200px" }} h="100vh" bg="green.500">
        <Text>Box 1</Text>
        <MenuItems />
      </Box>
      <Box flex="1" bg="tomato" h="100vh">
        <WeatherForecast />
      </Box>
    </Flex>
  );
};

const MenuItems = () => {
  return (
    <>
      {LinkItems.map((value, index) => {
        return (
          <div key={index}>
            <Link href={value.url}>
              <Box
                h="80px"
                p="4"
                border={"1px solid"}
                borderColor={"green.400"}
                cursor="pointer"
                _hover={{
                  bg: "green.300",
                  color: "white",
                }}
                borderRadius="lg"
                display="flex"
                justifyContent="space-between"
              >
                <Box display="flex">{value.name}</Box>
                <Box display="flex">
                  {" "}
                  <Icon
                    ml="4"
                    as={value.icon}
                    display={{ base: "none", md: "inline" }}
                  />
                </Box>
              </Box>
            </Link>
          </div>
        );
      })}
    </>
  );
};

export default SideBarChakraUI;
