import React, { useEffect, useState } from "react";
import axios, { AxiosResponse } from "axios";
import {
  Box,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Select,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import "chart.js/auto";
import { Line } from "react-chartjs-2";

type ResponseDaily = {
  latitude: number;
  longitude: number;
  generationtime_ms: number;
  utc_offset_seconds: number;
  timezone: string;
  timezone_abbreviation: string;
  elevatio: number;
  daily_units: {
    time: string;
    temperature_2m_max: string;
    temperature_2m_min: string;
  };
  daily: {
    time: Array<any>;
    temperature_2m_max: Array<number>;
    temperature_2m_min: Array<number>;
  };
};

type ResponseHourly = {
  latitude: number;
  longitude: number;
  generationtime_ms: number;
  utc_offset_seconds: number;
  timezone: string;
  timezone_abbreviation: string;
  elevatio: number;
  hourly_units: {
    time: string;
    temperature_2m: string;
  };
  hourly: {
    time: Array<any>;
    temperature_2m: Array<number>;
  };
};

const baseUrl = "https://api.open-meteo.com/v1/forecast";

// {
//     "latitude": 35.7, "longitude": 139.6875, "generationtime_ms": 1.9359588623046875, "utc_offset_seconds": 32400, "timezone": "Asia/Tokyo", "timezone_abbreviation": "JST",
//         "elevation": 43.0, "daily_units": { "time": "iso8601", "temperature_2m_max": "°C", "temperature_2m_min": "°C" },
//     "daily": {
//         "time": ["2023-01-29", "2023-01-30", "2023-01-31", "2023-02-01", "2023-02-02", "2023-02-03", "2023-02-04"],
//             "temperature_2m_max": [7.1, 10.4, 7.2, 13.6, 9.0, 4.5, 9.4], "temperature_2m_min": [-1.2, -1.8, 0.2, -0.9, 3.6, 1.7, 1.9]
//     }
// }

type positionCoordinate = {
  placeName: string;
  latitude: string;
  longitude: string;
};

const positionInfos: positionCoordinate[] = [
  { placeName: "Kawasaki", latitude: "35.5296", longitude: "139.7038" },
  {
    placeName: "Hokkaido",
    latitude: "43.0642",
    longitude: "141.3469",
  },
];
const WeatherForecast: React.FC = () => {
  const [responseDataDaily, setResponseDataDaily] = useState<ResponseDaily>();
  const [responseDataHourly, setResponseDataHourly] =
    useState<ResponseHourly>();
  const [selectedPlaceName, setSelectedPlaceName] = useState<string | null>(
    null
  );
  const [latitude, setLatitude] = useState<string>();
  const [longitude, setLongitude] = useState<string>();
  const [interval, setInterval] = useState<("daily" | "hourly") | null>(null);
  const [elementDaily, setElementDaily] = useState<string>(
    "temperature_2m_max,temperature_2m_min"
  );
  const [elementHourly, setElementHourly] = useState<string>("temperature_2m");
  // "temperature_2m_max,temperature_2m_min"
  const [timezone, setTimezone] = useState<string>("Asia%2FTokyo");

  const urlDaily =
    baseUrl +
    "?latitude=" +
    latitude +
    "&longitude=" +
    longitude +
    "&" +
    interval +
    "=" +
    elementDaily +
    "&timezone=" +
    timezone;

  const urlHourly =
    baseUrl +
    "?latitude=" +
    latitude +
    "&longitude=" +
    longitude +
    "&" +
    interval +
    "=" +
    elementHourly +
    "&timezone=" +
    timezone;

  useEffect(() => {
    console.log("kousin");
    const doFunction = async (): Promise<void> => {
      try {
        let response: AxiosResponse;
        if (interval !== null && selectedPlaceName !== null) {
          if (interval === "daily") {
            window.alert("dailyモードでデータ取得");
            console.log(urlDaily);
            response = await axios.get(urlDaily);
            setResponseDataDaily(response.data);
          } else {
            window.alert("hourlyモードでデータ取得");
            response = await axios.get(urlHourly);
            setResponseDataHourly(response.data);
          }
        }
      } catch (error: unknown) {
        window.alert("Axios Error");
        axios.isAxiosError(error);
      }
    };
    doFunction();
  }, [interval, urlDaily, urlHourly]);
  const getTableData = (): Array<any> => {
    // console.log(responseData);
    let resTimes: string[];
    let resMaxTmps: number[], resMinTmps: number[], resTmps: number[];
    let res: Array<any> = [];

    if (responseDataDaily !== undefined && interval === "daily") {
      resTimes = responseDataDaily.daily.time;
      resMaxTmps = responseDataDaily.daily.temperature_2m_max;
      resMinTmps = responseDataDaily.daily.temperature_2m_min;
      resTimes.forEach((value, index, arr) => {
        console.log("resTimes:", value);
        console.log("resMaxTemps", resMaxTmps[index]);
        console.log("resMinTemp", resMinTmps[index]);
        const pushedResMaxTmp = resMaxTmps[index];
        const pushedResMinTmp = resMinTmps[index];
        res.push({ value, pushedResMaxTmp, pushedResMinTmp });
      });
    } else if (responseDataHourly !== undefined && interval === "hourly") {
      resTimes = responseDataHourly.hourly.time;
      resTmps = responseDataHourly.hourly.temperature_2m;
      resTimes.forEach((value, index, arr) => {
        console.log("resTimes:", value);
        console.log("resTmps", resTmps[index]);
        const pushedResTmps = resTmps[index];
        res.push({ value, pushedResTmps });
      });
    }

    return res;
  };

  return (
    <div>
      <Box h="20vh">
        {selectedPlaceName}
        <FormControl>
          <Select
            placeholder="Select"
            onChange={(e) => {
              window.alert(e.target.value);
              setSelectedPlaceName(e.target.value);
              const positionIndex = positionInfos.findIndex(
                ({ placeName }) => placeName === e.target.value
              );
              setLatitude(positionInfos[positionIndex].latitude);
              setLongitude(positionInfos[positionIndex].longitude);
            }}
          >
            {positionInfos.map((value, index) => {
              return <option>{value.placeName}</option>;
            })}
          </Select>

          <Select
            placeholder="Select"
            onChange={(e) => {
              if (e.target.value === "daily" || e.target.value === "hourly")
                setInterval(e.target.value);
            }}
          >
            <option>hourly</option>
            <option>daily</option>
          </Select>
        </FormControl>
      </Box>
      {/* ★ */}
      {/* 
      {getTime(interval).map((value, index) => {
        return (
          <div>
            {value.value}★{value.pushedResTmps}
          </div>
        );
      })} */}
      <Flex>
        <DescriptionTable
          interval={interval}
          data={getTableData()}
        ></DescriptionTable>
        <DescriptionGraph
          interval={interval}
          dailyData={responseDataDaily}
          hourlyData={responseDataHourly}
        ></DescriptionGraph>
      </Flex>
    </div>
  );
};

const DescriptionTable = (props: {
  interval: "daily" | "hourly" | null;
  data: Array<any>;
}) => {
  if (props.interval === "hourly") {
    return (
      <Flex h="80vh" flex="1">
        <TableContainer overflowX="unset" overflowY="auto" h="90%" w="90%">
          <Table backgroundColor="#FFFF99" color="black" border="4px">
            <Thead position="sticky" top={-1} bgColor="gray.300">
              <Tr>
                <Th borderColor="black">Date</Th>
                <Th borderColor="black">Temperture</Th>
              </Tr>
            </Thead>
            <Tbody>
              {props.data.map((value, index) => {
                return (
                  <Tr
                    key={index}
                    borderColor="black"
                    _hover={{
                      backgroundColor: "#fcfcd2",
                    }}
                  >
                    {" "}
                    <Td borderColor="black">{value.value}</Td>
                    <Td borderColor="black">{value.pushedResTmps}</Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>
      </Flex>
    );
  } else if (props.interval === "daily") {
    return (
      <Flex h="80vh" flex="1">
        {props.interval}
        {props.data.map((value, index) => {
          return (
            <div>
              {value.value}★{value.pushedResMinTmp}★{value.pushedResMaxTmp}
            </div>
          );
        })}
      </Flex>
    );
  } else {
    return <></>;
  }
};

const DescriptionGraph = (props: {
  interval?: "daily" | "hourly" | null;
  dailyData?: ResponseDaily;
  hourlyData?: ResponseHourly;
}) => {
  if (props.interval === "hourly" && props.hourlyData !== undefined) {
    window.alert("hourly graph");
    return (
      <Flex flex="1" bgColor="gray.300">
        <Line
          height="40%"
          width="40%"
          options={{ maintainAspectRatio: true }}
          id="chart-key"
          data={{
            labels: props.hourlyData.hourly.time,
            datasets: [
              {
                label: "",
                data: props.hourlyData.hourly.temperature_2m,
                borderColor: "rgb(75, 192, 192)",
              },
            ],
          }}
        ></Line>
      </Flex>
    );
  } else if (props.interval === "daily" && props.dailyData !== undefined) {
    return (
      <Flex flex="1" bgColor="gray.300">
        daily graph
      </Flex>
    );
  } else {
    return <Flex>エラー</Flex>;
  }
};

export default WeatherForecast;
